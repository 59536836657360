const FooterInfo = () => {
  return (
    <section class="info_section layout_padding-top layout_padding2-bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="info_links pl-lg-5">
              <h4>Menu</h4>
              <ul>
                <li class="active">
                  <a href="/home"> Home </a>
                </li>
                <li>
                  <a href="/about"> About </a>
                </li>
                <li>
                  <a class="" href="/portfolio">
                    Portfolio{" "}
                  </a>
                </li>
                <li>
                  <a class="" href="/services">
                    Services
                  </a>
                </li>
                <li>
                  <a href="/contactus"> Contact Us </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="info_contact">
              <h4>Location</h4>
              <div>
                <img src="images/location.png" alt="" />
                <p>915 Thakurkulhi, Dhaiya, Dhanbad - 826004</p>
              </div>
              <div>
                <img src="images/telephone.png" alt="" />
                <p>( 0326 7968688 )</p>
              </div>
              <div>
                <img src="images/envelope.png" alt="" />
                <p>contact@edutoj.com</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="info_social">
              <h4>Find us on</h4>
              <div class="social_container">
                <div>
                  <a href="">
                    <img src="images/facebook-logo.png" alt="" />
                  </a>
                </div>
                <div>
                  <a href="">
                    <img src="images/twitter-logo.png" alt="" />
                  </a>
                </div>
                <div>
                  <a href="">
                    <img src="images/instagram.png" alt="" />
                  </a>
                </div>
                <div>
                  <a href="">
                    <img src="images/linkedin-sign.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="info_form">
              <h4>Newsletter</h4>
              <form action="#">
                <input type="text" placeholder="Enter Your Email" />
                <button type="submit">Subscribe</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterInfo;
