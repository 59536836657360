import { Link } from "react-router-dom";

const CustomMenu = () => {
  return (
    <div class="custom_menu-container">
      <div class="container">
        <div class="custom_menu">
          <ul class="navbar-nav">
            <li class="nav-item active">
              <Link className="nav-link p1-0" to={"/home"}>
                Home
              </Link>
            </li>
            <li class="nav-item">
              <Link className="nav-link" to={"/about"}>
                About
              </Link>
            </li>
            <li class="nav-item">
              <Link className="nav-link" to={"/portfolio"}>
                Portfolio
              </Link>
            </li>
            <li class="nav-item">
              <Link className="nav-link" to={"/services"}>
                Services
              </Link>
            </li>

            <li class="nav-item">
              <Link className="nav-link" to={"/contactus"}>
                Contact Us
              </Link>
            </li>
          </ul>
          <div class="user_option">
            <div class="login_btn-container">
              <a href=""> Login </a>
            </div>
            <form class="form-inline my-2 my-lg-0">
              <button
                class="btn my-2 my-sm-0 nav_search-btn"
                type="submit"
              ></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomMenu;
