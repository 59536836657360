import { useEffect } from "react";
import CustomMenu from "./CustomMenu";
import Carousel from "nuka-carousel";

const Home = () => {
  return (
    <div class="hero_area">
      <header class="header_section">
        <div class="container-fluid">
          <nav class="navbar navbar-expand-lg custom_nav-container pt-3">
            <a class="navbar-brand" href="/home">
              <img src="images/edutoj-logo.png" alt="" />
              <span> Edutoj Technology </span>
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ml-auto mr-2">
                <li class="nav-item active">
                  <a class="nav-link" href="/home">
                    Home <span class="sr-only">(current)</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/about">
                    About{" "}
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/portfolio">
                    Portfolio{" "}
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/services">
                    Services
                  </a>
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="/contactus">
                    Contact us
                  </a>
                </li>
              </ul>
              <div class="user_option">
                <div class="login_btn-container">
                  <a href=""> Login </a>
                </div>
                <form class="form-inline my-2 my-lg-0">
                  <button
                    class="btn my-2 my-sm-0 nav_search-btn"
                    type="submit"
                  ></button>
                </form>
              </div>
            </div>
            <div class="call_btn">
              <a href=""> Call: 0326 7968688 </a>
            </div>
          </nav>
        </div>
      </header>

      <section class="slider_section">
        <div class="container-fluid">
          <Carousel autoplay={true} speed={1500} wrapAround={true} autoplayInterval={5000}>
            <div class="carousel-item active">
              <div class="row">
                <div class="col-md-3 col-lg-2 offset-md-2">
                  <div class="detail-box">
                    <h1>We imagine.</h1>
                    <p>
                      Design and deliver new digital experiences, revenue
                      streams and business models to meet rising customer
                      expectations and accelerate your growth.
                    </p>
                    <div>
                      <a href=""> Read More </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-7 col-lg-8">
                  <div class="img-box">
                    <img src="images/hero.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item active">
              <div class="row">
                <div class="col-md-3 col-lg-2 offset-md-2">
                  <div class="detail-box">
                    <h1>We engineer.</h1>
                    <p>
                      Streamline your software engineering to drive greater
                      efficiency and enable resiliency across your products and
                      platforms.
                    </p>
                    <div>
                      <a href=""> Read More </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-7 col-lg-8">
                  <div class="img-box">
                    <img src="images/hero.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item active">
              <div class="row">
                <div class="col-md-3 col-lg-2 offset-md-2">
                  <div class="detail-box">
                    <h1>We modernize.</h1>
                    <p>
                      Reinvent your applications, infrastructure and processes
                      for greater agility by taking full advantage of
                      automation, AI and cloud.
                    </p>
                    <div>
                      <a href=""> Read More </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-7 col-lg-8">
                  <div class="img-box">
                    <img src="images/hero.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item active">
              <div class="row">
                <div class="col-md-3 col-lg-2 offset-md-2">
                  <div class="detail-box">
                    <h1>We manage.</h1>
                    <p>
                      Transform and optimize the heartbeat of your operations,
                      systems and product development to reduce costs and
                      facilitate growth.
                    </p>
                    <div>
                      <a href=""> Read More </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-7 col-lg-8">
                  <div class="img-box">
                    <img src="images/hero.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </section>

      <CustomMenu />
    </div>
  );
};

export default Home;
