import "./App.css";
import About from "./components/public/About";
import CopyRight from "./components/public/CopyRight";
import FooterInfo from "./components/public/FooterInfo";
import LandingPage from "./components/public/LandingPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WhatWeDo from "./components/public/WhatWeDo";
import Contact from "./components/public/Contact";
import Portfolio from "./components/public/Portfolio";
import Header from "./components/public/Header";

function App() {
  return (
    <Router>     
      <Routes>
        <Route exact path="/home" element={<LandingPage />}></Route>
        <Route exact path="/about" element={<About />}></Route>
        <Route exact path="/services" element={<WhatWeDo />}></Route>
        <Route exact path="/contactus" element={<Contact />}></Route>
        <Route exact path="/portfolio" element={<Portfolio />}></Route>
        <Route exact path="/" element={<LandingPage />}></Route>
      </Routes>
      <FooterInfo />
      <CopyRight />
    </Router>
  );
}

export default App;
